import { ContainerProps, FullWidthContainer } from '.'
import { LoadingBoundaryProps } from '../LoadingBoundary'
import { LoadingBoundary } from '../LoadingBoundary/LoadingBoundary'

export type Props = ContainerProps & LoadingBoundaryProps

export const HideLoadingContainer = ({
  children,
  isLoading,
  isError,
  LoadingComponent,
  ErrorComponent,
  ...containerProps
}: Props) => {
  const loadingProps = {
    isLoading,
    isError,
    LoadingComponent,
    ErrorComponent
  }

  return (
    <FullWidthContainer {...containerProps}>
      <LoadingBoundary {...loadingProps}>{children}</LoadingBoundary>
    </FullWidthContainer>
  )
}
