import ClipLoader from 'react-spinners/ClipLoader'

export interface Props {
  children: React.ReactNode
  isLoading: boolean
  isError?: boolean
  LoadingComponent?:
    | JSX.Element
    | string
    | null
    | (JSX.Element | string | null)[]
    | (() => JSX.Element)
  ErrorComponent?:
    | JSX.Element
    | string
    | null
    | (JSX.Element | string | null)[]
    | (() => JSX.Element)
}

export const LoadingBoundary = ({
  children,
  isLoading,
  isError = false,
  LoadingComponent = <ClipLoader />,
  ErrorComponent = null
}: Props): JSX.Element => {
  // remove section if data not loaded correctly
  if (!isLoading && isError) {
    if (typeof ErrorComponent === 'function') {
      return <ErrorComponent />
    }

    // Fragment used to convert ErrorComponent to JSX.Element
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{ErrorComponent}</>
  }

  // hide seciton contents (but don't remove from flow) while loading
  return (
    <div className='relative'>
      {isLoading ? (
        <div className='absolute inset-0 flex justify-center items-center'>
          {typeof LoadingComponent === 'function' ? (
            <LoadingComponent />
          ) : (
            LoadingComponent
          )}
        </div>
      ) : null}
      <div className={`h-full w-full ${isLoading ? 'invisible' : ''}`}>
        {children}
      </div>
    </div>
  )
}
