export {
  BlockQuote,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  HeadingDisplay,
  Heading2Display,
  Paragraph,
  ParagraphDisplay,
  ParagraphDisplaySpan,
  ParagraphIntro,
  ParagraphNoSpace,
  ParagraphSmaller,
  SpanDisplay,
  SpanDisplayAlt,
  Subtitle,
  Span,
  Intro,
  MenuSectionTitle
} from './Typography'
